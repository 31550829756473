function Display() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="icon"
        d="M20 12H22V18.5C22 19.88 20.88 21 19.5 21H4.5C3.12 21 2 19.88 2 18.5V5.49997C2 4.11997 3.12 2.99997 4.5 2.99997H11V4.99997H4.5C4.22 4.99997 4 5.21997 4 5.49997V18.5C4 18.78 4.22 19 4.5 19H19.5C19.77 19 20 18.78 20 18.5V12ZM22.31 5.21997L15.98 12.4C15.78 14.42 14.07 16 12 16H8V12C8 9.92997 9.58 8.21997 11.6 8.01997L18.78 1.68997C19.77 0.809974 21.27 0.859973 22.21 1.78997C23.14 2.72997 23.19 4.22997 22.31 5.21997ZM20.79 3.20997C20.6 3.01997 20.3 3.00997 20.1 3.18997L14.02 8.54997C14.61 8.89997 15.1 9.38997 15.45 9.97997L20.81 3.89997C20.99 3.69997 20.98 3.39997 20.79 3.20997Z"
        fill="white"
      />
    </svg>
  );
}

export default Display;
